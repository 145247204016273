import { email } from "../../js/path";

const beautify = require("js-beautify").html;

export default {
  data() {
    return {
      previewEmail: false,
      template_ref_no: null,
      table_header: [],
      fields: [
        {
          key: "id",
        },
        // {
        //   key: "endpoint_id",
        //   label: "Endpoint",
        //   class: "c_touchpt_header",
        // },
        {
          key: "email_type",
          label: "Email Type",
          class: "c_touchpt_header text-center",
        },
        {
          key: "from",
          class: "c_touchpt_header text-center",
        },
        {
          key: "replyTo",
          class: "c_touchpt_header text-center",
        },
        {
          key: "subject",
          class: "c_touchpt_header text-center",
        },
        {
          key: "content",
          label: "Email HTML Content",
          class: "c_touchpt_header text-center",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      title: "Email Content",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      key: 0,
      currentPage: 1,
      activeTab: "all",
      params: "",
      endpoint_dropdown: [],
      form: {
        endpoint_id: "",
        email_type: "",
        from: "",
        replyTo: "",
        subject: "",
        content: "",
        placeholders: "",
      },
    };
  },
  methods: {
    showPreview() {
      this.previewEmail = !this.previewEmail;
    },
    formatCode() {
      const formattedContent = beautify(this.form.content, {
        indent_size: 2,
      });
      this.form.content = formattedContent;
    },
    editorInit(editor) {
      if (editor) {
        editor.container.style.fontSize = "18px";
      } else {
        console.error("Editor instance is not defined.");
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) this.fetchData(this.activeTab);
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.fetchData(this.activeTab);
      } else {
        this.fetchData(this.activeTab);
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(pagination = null) {
      console.log("Fetching");
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = email.getEmailContent;
        if (pagination == "search") {
          url = email.getEmailContent + "?search=" + this.filter;
        } else if (pagination) {
          url = url + "?page=" + pagination;
          if (this.filter) {
            url = url + "&search=" + this.filter;
          }
        }
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchEmail(template_ref_no) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = email.getEmailContent + "/" + template_ref_no;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (
            responseData.endpoint_id != "" &&
            responseData.endpoint_id != null
          ) {
            this.form.endpoint_id = responseData.endpoint_id;
          }
          if (
            responseData.email_type != "" &&
            responseData.email_type != null
          ) {
            this.form.email_type = responseData.email_type;
          }
          if (responseData.from != "" && responseData.from != null) {
            this.form.from = responseData.from;
          }
          if (responseData.replyTo != "" && responseData.replyTo != null) {
            this.form.replyTo = responseData.replyTo;
          }
          if (responseData.subject != "" && responseData.subject != null) {
            this.form.subject = responseData.subject;
          }
          if (responseData.content != "" && responseData.content != null) {
            this.form.content = responseData.content;
          }
          if (
            responseData.placeholders != "" &&
            responseData.placeholders != null
          ) {
            this.form.placeholders = responseData.placeholders;
          }
          if (responseData.endpoint_dropdown.length > 0) {
            responseData.endpoint_dropdown.map((item) => {
              console.log(item, "item");
              this.endpoint_dropdown.push({
                key: item.key,
                label: item.label,
              });
            });
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        if (this.form.content == "") {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Email HTML Content is required",
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);
        let url = email.getEmailContent;
        if (this.$route.name == "edit-email-content") {
          url = email.getEmailContent + "/" + this.$route.params.id;
        }
        let dataAppend = new FormData();
        if (this.$route.name == "add-email-content") {
          dataAppend.append("endpoint_id", "");
        } else {
          dataAppend.append("endpoint_id", this.form.endpoint_id);
        }
        for (var key in this.form) {
          if (key != "endpoint_id") {
            dataAppend.append(key, this.form[key]);
          }
        }
        if (this.$route.name == "edit-email-content") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/email-content");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteEmailContent(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = email.getEmailContent + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          this.params = `&page=${value}`;
          this.fetchData(value);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    if (
      this.$route.name == "add-email-content" ||
      this.$route.name == "edit-email-content"
    ) {
      if (this.$route.name == "edit-email-content") {
        this.fetchEmail(this.$route.params.id);
      }
    } else {
      this.fetchData("all");
    }
  },
};
